var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-card',{staticClass:"p-2",attrs:{"no-body":""}},[_c('b-form',{attrs:{"enctype":"multipart/form-data","method":"post"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('label',{staticClass:"m-0",attrs:{"for":"disclaimer"}},[_vm._v("Choose User's Role")]),_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"role_name","options":_vm.new_user_roles,"placeholder":"* User Role"},model:{value:(_vm.form.user_roles),callback:function ($$v) {_vm.$set(_vm.form, "user_roles", $$v)},expression:"form.user_roles"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"First Name","type":"text"},model:{value:(_vm.form.user_fname),callback:function ($$v) {_vm.$set(_vm.form, "user_fname", $$v)},expression:"form.user_fname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Last Name","type":"text"},model:{value:(_vm.form.user_lname),callback:function ($$v) {_vm.$set(_vm.form, "user_lname", $$v)},expression:"form.user_lname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Email","type":"email"},model:{value:(_vm.form.user_email),callback:function ($$v) {_vm.$set(_vm.form, "user_email", $$v)},expression:"form.user_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Phone","type":"number","max":"13","step":"0"},model:{value:(_vm.form.user_phone),callback:function ($$v) {_vm.$set(_vm.form, "user_phone", $$v)},expression:"form.user_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Username","type":"text"},model:{value:(_vm.form.user_username),callback:function ($$v) {_vm.$set(_vm.form, "user_username", $$v)},expression:"form.user_username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('b-form-datepicker',{attrs:{"id":"example-datepicker","placeholder":"Date of Birth"},model:{value:(_vm.form.user_dob),callback:function ($$v) {_vm.$set(_vm.form, "user_dob", $$v)},expression:"form.user_dob"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('v-select',{attrs:{"id":"countries-select","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.countries,"placeholder":"Select Country"},on:{"input":_vm.onSelectCountry},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1),_c('b-col',{attrs:{"md":"4"}},[_c('div',{staticClass:"demo-inline-spacing"},[_c('label',{staticClass:"status",attrs:{"for":""}},[_vm._v("Status (Active / In-Active)")]),_c('b-form-checkbox',{staticClass:"custom-control-success status",attrs:{"checked":"true","name":"check-button","switch":""},model:{value:(_vm.form.user_status),callback:function ($$v) {_vm.$set(_vm.form, "user_status", $$v)},expression:"form.user_status"}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1),_c('span',{staticClass:"switch-icon-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)])],1)])],1),_c('b-row',[_c('b-col',{attrs:{"md":"2"}},[_c('b-button',{staticClass:"btn-block mt-1",attrs:{"variant":"success","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_vm._v(" Save User ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }